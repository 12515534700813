@import 'scss/1-abstracts/';
.wrapper {
  display: flex;
  flex-direction: column;
  font-family: $body-font;
  label {
    @include form-label;
  }
  .password {
    position: relative;
    width: 100%;
    button {
      position: absolute;
      right: 12px;
      top: calc(50% + 1px);
      transform: translateY(-50%);
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      &:focus-visible {
        box-shadow: 0px 0px 0px 4px get-color('purple', '50');
        outline: 1px solid get-color('purple', '500');
      }
      svg {
        width: 14px;
        height: 14px;
        path {
          stroke: get-color('neutral', '400');
          fill: get-color('neutral', '400');
        }
      }
    }
    input {
      width: 100%;
    }
  }
  input {
    height: $form-element-height;
    border: 1px solid $border-color;
    color: $form-field-text-color;
    padding: 12px 42px 12px 12px;
    border-radius: 6px;
    box-shadow: 0px 1px 2px $form-element-shadow-color;
    &:focus {
      border: 1px solid get-color('purple', '300');
      box-shadow: 0px 0px 0px 4px get-color('purple', '50');
      outline: none;
    }
    &:focus-visible {
      outline: none;
    }
    &::placeholder {
      color: get-color('neutral', '300');
    }
    &.error {
      border: 1px solid red;
    }
    &:disabled {
      color: get-color('neutral', '300');
      background-color: get-color('neutral', '100');
    }
  }
  .helper {
    margin-top: 0.5em;
    color: get-color('neutral', '500');
  }
}

.error {
  color: $error-color;
  font-size: get-font-size(1);
}
span.error {
  margin-top: 8px;
}
