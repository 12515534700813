@import 'scss/1-abstracts';

.cs-tabs .ant-tabs-nav {
  .ant-tabs-nav-wrap {
    margin-bottom: 16px;
  }
  &::before {
    display: none;
  }
  .ant-tabs-tab {
    padding: 6px 16px;
    border-radius: 500px;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    background-color: get-color('purple', '50');
    .ant-tabs-tab-btn {
      color: get-color('purple', '500');
    }
  }
  .ant-tabs-tab .ant-tabs-tab-btn {
    font-weight: 500 !important;
    color: get-color('purple', '500');
    font-size: 16px;
    line-height: 24px;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
}

.cs-file-tabs .ant-row {
  margin: 0;
}

// .cs-tabs .ant-tabs-tabpane-active > div > div:last-of-type > div {
//   border-bottom: 0; // small fix for tables with tabs to dont have bottom border on last elemet
// }
