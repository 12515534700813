@import 'scss/1-abstracts/';

.wrapper {
  $icon-size: 16px;
  $modal-width: 320px;
  position: relative;
  display: inline;
  margin-left: 8px;
  z-index: 2;
  svg {
    width: $icon-size;
    height: 1$icon-size;
    path {
      stroke: $primary-color;
    }
  }
  &:hover {
    .modal {
      visibility: visible;
      opacity: 1;
    }
  }
  .modal {
    $triangleSize: 16px;
    min-width: $modal-width;
    visibility: hidden;
    opacity: 0;
    padding: 12px 24px;
    position: absolute;
    bottom: calc($icon-size * 2);
    left: 0;
    background-color: $text-secondary;
    width: max-content;
    box-shadow: 0px 1px 12px 0px #00000025;
    z-index: 3;
    .header {
      font-weight: 700;
      font-size: 24px;
      width: 100%;
      margin-bottom: 16px;
    }
    &::before {
      content: '';
      position: absolute;
      bottom: calc(
        -1 * $triangleSize
      ); // position at the bottom of the tooltip
      left: 0;
      width: $triangleSize;
      height: $triangleSize;
      background-color: $text-secondary;
      clip-path: polygon(50% 100%, 0 0, 100% 0);
    }
  }
}
