@import 'scss/1-abstracts';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid get-color('neutral', '200');
  &.focused {
    border: 1px solid get-color('purple', '500');
    box-shadow: 0px 0px 0px 4px get-color('purple', '50');
  }
}
