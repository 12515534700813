body {
  display: flex;
  flex-flow: column;
  height: 100vh;
  background-color: white;
  z-index: 1;
}

#root {
  flex: 1;
}

* {
  box-sizing: border-box;
}

a {
  color: $primary-color;
  display: contents;
  text-decoration: none;
  &:hover {
    color: get-color('purple', '600');
  }
}

p {
  margin-bottom: 0;
}

button {
  background: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  font-family: $body-font;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
