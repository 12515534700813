@import 'scss/1-abstracts/';

.wrapper {
  position: fixed;
  top: $header-height;
  left: 0;
  background-color: $bg-secondary;
  z-index: 10;
  width: $sidebar-width;
  height: calc(100vh - #{$header-height});
  @include transition();
  padding: 0;
  flex-direction: column;

  &:before {
    content: '';
    width: 20px;
    height: 40px;
    background: transparent;
    border-top-left-radius: 5px;
    top: 0;
    left: 100%;
    position: absolute;
    box-shadow: -10px 0 0 0 $bg-secondary;
    pointer-events: none;
    z-index: 1;
  }

  .separator {
    position: relative;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    &::after {
      position: absolute;
      content: '';
      height: 1px;
      width: 100%;
      background-color: get-color('neutral', '200');
      opacity: 0.4;
    }
  }

  .list {
    width: 100%;
    background-color: $bg-secondary;
    height: calc(100% - #{$sidebar-footer-height});
    max-height: max-content;
    display: flex;
    flex-direction: column;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 10px;
      height: 100%;
      background-color: $bg-secondary;
      z-index: 2;
    }
  }
  .item {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding-left: 48px;
    color: $text-secondary;
    background-color: $bg-primary;
    font-weight: 400;
    z-index: 1;
    isolation: isolate;
    // border: 1px solid red;
    @include transition();
    svg path {
      fill: transparent;
      stroke: $text-secondary;
    }
    .fill svg path {
      fill: $text-secondary;
      stroke: transparent;
    }
    &:hover,
    &:focus {
      color: $text-secondary;
    }
    .link {
      color: $text-secondary;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }
    .label {
      margin-left: 16px;
      transition: opacity ease-in-out 0.1s $transition-speed;
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      display: flex;
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      background-color: $bg-secondary;
    }
    &:hover::after {
      background-color: get-color('purple', '800');
      @include transition();
    }
  }
  .item.active {
    .link {
      color: $text-primary;
    }
    svg path {
      fill: transparent;
      stroke: $text-primary;
    }
    .fill svg path {
      fill: $text-primary;
      stroke: transparent;
    }
    &::after {
      background-color: $bg-primary;
    }
    &::before {
      filter: invert(1);
    }
  }
  .item:has(+ .active) {
    &::after {
      border-bottom-right-radius: 5px;
    }
  }
  .item:last-of-type {
    &::after {
      border-bottom-right-radius: 0; // maybe better solution
    }
  }
  .item.active + .item {
    &::after {
      border-top-right-radius: 5px;
    }
  }
  a.item.active:first-of-type .link:after {
    content: '';
    width: 20px;
    height: 40px;
    background: transparent;
    border-bottom-right-radius: 5px;
    bottom: 100%;
    right: 0;
    position: absolute;
    box-shadow: 0 20px 0 0 get-color('neutral', 'white');
    pointer-events: none;
    z-index: 1;
  }
  a.item.active:last-of-type .link:after {
    content: '';
    width: 20px;
    height: 40px;
    background: transparent;
    border-top-right-radius: 5px;
    top: 100%;
    right: 0;
    position: absolute;
    box-shadow: 0 -20px 0 0 get-color('neutral', 'white');
    pointer-events: none;
  }
  .sidebarControll {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    &:hover {
      background-color: get-color('purple', '800');
      @include transition();
    }
    .iconBack {
      display: block;
      position: relative;
      left: calc(100% - 16px);
      svg path {
        stroke: $text-secondary !important;
      }
    }
  }
}

.wrapper.collapsed {
  width: $sidebar-width-collapsed;
  @include transition();

  .item {
    padding-left: 24px;
    @include transition();
  }

  .item .label {
    opacity: 0;
    visibility: hidden;
    transition: none !important;
  }
  .sidebarControll {
    .iconBack {
      left: calc(100% - 40px);
      transform: rotateZ(180deg);
      transition: transform 0.3s;
    }
  }
}

.wrapper.collapsed {
  .approveBtn {
    padding: 10px 12px;
    transition: none;
    svg {
      margin-right: 0;
    }
    .btnTxt {
      opacity: 0;
      visibility: hidden;
      transition: none !important;
      max-width: 0;
    }
  }
}
