@import 'scss/1-abstracts';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 12px 12px;
  border-bottom: 1px solid get-color('neutral', '200');
  gap: 16px;

  & > div {
    display: flex;
  }
  svg {
    margin-right: 8px;
  }
  & > div svg path {
    stroke: get-color('success', '600');
  }
  .name p {
    font-weight: 600;
    color: get-color('neutral', '1000');
    margin-bottom: 4px;
  }
  .name span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: get-color('neutral', '500');
  }
}
