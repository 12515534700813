@use './functions';
//---Colors With shades Map---//
$colors: (
  'purple': (
    '50': #eee8fd,
    '100': #ddd0fa,
    '200': #bba1f5,
    '300': #9972f0,
    '400': #7743eb,
    '500': #5514e6,
    '600': #4410b8,
    '800': #22085c,
    '900': #11042e,
    'base': #5514e6
  ),
  'blue': (
    '50': #e9eeff,
    '100': #d2ddff,
    '200': #a5bcff,
    '300': #799aff,
    '400': #4c79ff,
    '500': #1f57ff,
    '600': #1946cc,
    '700': #133499,
    '900': #061133,
    'base': #1f57ff
  ),
  'turquoise': (
    '50': #e6f7f9,
    '100': #ccf0f4,
    '200': #99e1e8,
    '300': #66d1dd,
    '400': #33c2d1,
    '500': #00b3c6,
    '600': #008f9e,
    '700': #006b77,
    'base': #00b3c6
  ),
  'warning': (
    '50': #fff5e6,
    '100': #ffebcc,
    '200': #ffd699,
    '300': #ffc266,
    '400': #ffad33,
    '500': #ff9900,
    '600': #cc7a00,
    '700': #995c00,
    '900': #331f00,
    'base': #ff9900
  ),
  'success': (
    '50': #f0faeb,
    '100': #e0f5d6,
    '200': #c2ebad,
    '300': #a3e085,
    '400': #85d65c,
    '500': #66cc33,
    '600': #52a329,
    '700': #3d7a1f,
    '900': #14290a,
    'base': #52a329
  ),
  'alert': (
    '50': #ffe6e6,
    '100': #ffcccc,
    '200': #ff9999,
    '300': #ff6666,
    '400': #ff3333,
    '500': #ff0000,
    '600': #cc0000,
    '700': #990000,
    'base': #cc0000
  ),
  'neutral': (
    '0': #ffffff,
    '50': #fdfdfd,
    '75': #f9f9f9,
    '100': #f3f3f3,
    '200': #e9e9e9,
    '300': #cfcfcf,
    '400': #a1a1a1,
    '500': #737373,
    '600': #5c5c5c,
    '700': #454545,
    '800': #2e2e2e,
    '900': #171717,
    '1000': #000000,
    'white': #ffffff,
    'black': #000000,
    'base': #737373,
    'extra': #fdfdfd,
    'dark': #393939
  )
);
//---Gradients---//
$gradient-blurple: linear-gradient(45deg, #285bf2 0%, #cd29ff 100%);
$gradient-blurple-60: linear-gradient(60deg, #285bf2 0%, #cd29ff 100%);
$gradient-blurple-light: linear-gradient(45deg, #9972f0 0%, #66d1dd 100%);
$gradient-turple: linear-gradient(45deg, #eee8fd 0%, #e6f7f9 100%);
$gradient-loader: linear-gradient(
  45deg,
  transparent 0%,
  #285bf2 66%,
  #cd29ff 100%
);
//---Font Size Map---//
$base-font-size: 1rem;
$font-size: (
  0: 0.75rem,
  1: 0.875rem,
  2: 1rem,
  3: 1.25rem,
  4: 1.5rem,
  5: 2rem,
  6: 2.5rem,
  7: 3rem,
  8: 4rem
);
//---Spacing  Map---//
$base-spacing: 16px;
$spacing: (
  0: $base-spacing * 0,
  1: $base-spacing * 0.25,
  2: $base-spacing * 0.5,
  3: $base-spacing * 0.75,
  4: $base-spacing * 1,
  5: $base-spacing * 1.25,
  6: $base-spacing * 1.5,
  7: $base-spacing * 2,
  8: $base-spacing * 2.5,
  9: $base-spacing * 3,
  10: $base-spacing * 4,
  11: $base-spacing * 5,
  12: $base-spacing * 6,
  auto: auto
);
//---Font Weight Map---//
$font-weight: (
  100: 100,
  200: 200,
  300: 300,
  400: 400,
  500: 500,
  600: 600,
  700: 700,
  800: 800
);
//---Line Height Map---//
$line-height: (
  0: 1em,
  xs: 1.1em,
  sm: 1.25em,
  md: 1.5em,
  lg: 1.7em,
  xl: 1.7em
);
//---Icon Size Map---//
$icon-size-map: (
  sm: 16px,
  md: 16px,
  lg: 18px,
  xl: 24px
);
//---Button Size Map---//
$button-size: (
  sm: (
    h: 32px,
    w: 120px,
    f: 14px
  ),
  md: (
    h: 40px,
    w: 160px,
    f: 16px
  ),
  lg: (
    h: 54px,
    w: 240px,
    f: 20px
  )
);
//---Wrapper Map---//
$page-wrapper: (
  xxs: 480px,
  xs: 600px,
  sm: 800px,
  tablet: 1024px,
  md: 1240px,
  lg: 1440px,
  xl: 2400px
);
//---Breakpoints Map---//
$breakpoints: (
  'small-device': 400px,
  'phone': 768px,
  'sm-tablet': 992px,
  'tablet': 1024px,
  'lg-tablet': 1200px
);
//---Order---//
$order: (1, 2, 3, 4);
//---Layout Dimensions---//
$sidebar-width: 255px;
$sidebar-width-collapsed: 68px;
$header-height: 60px;
$max-content-width: 2400px;
$dashboard-content-width: 1440px;
$inner-content-width: 1180px;
$sidebar-footer-height: 80px;
$dropdown-width: 200px;
$main-page-padding: 100px;
$inner-container-width: 760px;
$customer-max-content-width: 960px;
//---wizard dimensions---//
$nav-tab-width: 160px;
$wizard-header-height: 144px;
$wizard-form-max-width: 1220px;
$wizard-form-narrow-width: 800px;
$logo-height: 48px;
//---customer dimensions---//
$mobile-header-height: auto;
$mobile-header-height-only-back: 100px;
$mobile-drawer-width-min: 350px;
$mobile-drawer-width-max: 90%;
$inverted-cutout: 25px;
$inverted-border-radius: 16px;
$fab-size: 60px;
//---Form Elements Basic Dimensions---//
$form-element-height: 48px;
$form-element-shadow-color: #1018280d;
$form-field-text-color: get-color('neutral', '900');
$checkbox-width: 24px;
$input-icon-padding: 24px;
$border-radius: 6px;
//-----------------
//--Utilitie colors
//-----------------
$primary-color: get-color('purple', '500');
$secondary-color: get-color('turquoise', '500');
$success-color: get-color('success', 'base');
$error-color: get-color('alert', 'base');
$warning-color: get-color('warning', 'base');
$muted-color: get-color('neutral', '300');
$bg-primary: get-color('neutral', '0');
$bg-secondary: get-color('purple', '900');
$text-primary: get-color('neutral', '800');
$text-secondary: get-color('neutral', '0');
$border-color: get-color('neutral', '300');
$disabled-bg: get-color('neutral', '100');
$disabled-text: get-color('neutral', '400');
$scrollbar-track: darken(get-color('purple', '900'), 8%);
$scrollbar-thumb: lighten(get-color('purple', '900'), 20%);
//-----------------
//--Fonts
//-----------------
$body-font: 'Roboto', sans-serif;
$heading-font: 'Inter', sans-serif;

//-----------------
//--Animations
//-----------------

$transition-speed: 0.3s;
