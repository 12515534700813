@import 'scss/1-abstracts';

.wrapper {
  border: 1px solid get-color('neutral', '200');
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  box-shadow: 0px 1px 10px -1px rgba(0, 0, 0, 0.05);
  background: #f9f9f9;
  margin-bottom: 16px;
  button {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #ededed;
    transition: ease-in-out 0.2s;
    &:hover {
      transition: ease-in-out 0.2s;
      background-color: $primary-color;
    }
  }

  .edit svg {
    width: 16px;
    height: auto;
    path {
      transition: ease-in-out 0.2s;
      fill: get-color('neutral', '1000');
    }
  }
  .delete svg {
    width: 22px;
    height: auto;
    path {
      transition: ease-in-out 0.2s;
      stroke: get-color('neutral', '1000');
    }
  }
  .edit:hover svg path {
    fill: get-color('neutral', '0');
  }
  .delete:hover svg path {
    stroke: get-color('neutral', '0');
  }
  .action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
