@import 'scss/1-abstracts';

.wrapper {
  $info-height: 48px;
  position: relative;
  height: 100%;
  .info {
    height: $info-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid get-color('neutral', '200');
    .name {
      font-size: 14px;
      color: get-color('neutral', '500');
      font-weight: 400;
    }
  }

  .controlls {
    display: flex;
    z-index: 10;
    button {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      svg {
        width: 100%;
        height: auto;
      }
    }
  }
  .previewContainer {
    height: calc(100% - $info-height);
    overflow: scroll;
  }

  .previewContainer img {
    // transform-origin: 0 0 !important;
  }
}
