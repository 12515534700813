@import 'scss/1-abstracts';

.wrapper {
  $border-style: 1px solid $border-color;
  $button-size: $form-element-height;
  display: inline-flex;
  width: max-content;
  button {
    width: $button-size;
    height: $button-size;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease-in-out 0.4s;
    border: $border-style;
    svg path {
      fill: get-color('neutral', '600');
      stroke: none;
      transition: ease-in-out 0.4s;
    }
    &:first-of-type {
      border-right: none;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
    &:last-of-type {
      border-left: none;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
    &:hover {
      background-color: $primary-color;
      transition: ease-in-out 0.4s;
      border-color: $primary-color;
      svg path {
        fill: $text-secondary;
        transition: ease-in-out 0.4s;
      }
    }
  }
  span {
    border: none;
    border: $border-style;
    width: $button-size;
    height: $button-size;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
