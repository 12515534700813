// ----------
//  Border utilitie classes
// ----------
.b--0 {
  border: none !important;
}

.b--1 {
  border: 1px solid $border-color !important;
}

.bb--1 {
  border-bottom: 1px solid $border-color !important;
}

.bb--2 {
  border-bottom: 2px solid $border-color !important;
}

.bt--1 {
  border-top: 1px solid $border-color !important;
}

.bt--2 {
  border-top: 2px solid $border-color !important;
}

.bl--1 {
  border-left: 1px solid $border-color !important;
}

.bl--2 {
  border-top: 2px solid $border-color !important;
}

.br-1 {
  border-top: 1px solid $border-color !important;
}

.br--2 {
  border-right: 2px solid $border-color !important;
}

.bra--0 {
  border-radius: 0;
}

.bra--sm {
  border-radius: 3px;
}

.bra--md {
  border-radius: 6px;
}

.bra--lg {
  border-radius: 10px;
}

.br--50 {
  border-radius: 50%;
}

.br--pill {
  border-radius: 500px; //this works for all modern browsers
}

// ----------
//  Font Families
// ----------
.font-roboto {
  font-family: $body-font;
}
.font-inter {
  font-family: $heading-font;
}

// ----------
//  Color utilitie classes -- text/bg-%%NAME%%-%%SHADE%%
//
//  Example:
//  text-purple-500
//  bg-neutral-200
// ----------
@each $color, $shades in $colors {
  @each $shade, $value in $shades {
    .text-#{$color}-#{$shade} {
      color: $value !important;
      &:not(.stroke) > svg path {
        fill: $value;
        stroke: none;
      }
      &.stroke path {
        fill: none;
        stroke: $value;
      }
    }
    .bg-#{$color}-#{$shade} {
      background-color: $value;
    }
    @if $shade== 'base' {
      .bc--#{$color} {
        border-color: $value !important;
      }
    }
  }
}

// ----------
//  Spacing utilitie classes  -- m - margins, p-paddings
// ----------
@each $space, $value in $spacing {
  .m--#{$space} {
    margin: $value !important;
  }
  .mx--#{$space} {
    margin-left: $value !important;
    margin-right: $value !important;
  }
  .my--#{$space} {
    margin-top: $value !important;
    margin-bottom: $value !important;
  }
  .ml--#{$space} {
    margin-left: $value !important;
  }
  .mr--#{$space} {
    margin-right: $value !important;
  }
  .mb--#{$space} {
    margin-bottom: $value !important;
  }
  .mt--#{$space} {
    margin-top: $value !important;
  }
  .p--#{$space} {
    padding: $value !important;
  }
  .px--#{$space} {
    padding-left: $value !important;
    padding-right: $value !important;
  }
  .py--#{$space} {
    padding-top: $value !important;
    padding-bottom: $value !important;
  }
  .pl--#{$space} {
    padding-left: $value !important;
  }
  .pr--#{$space} {
    padding-right: $value !important;
  }
  .pb--#{$space} {
    padding-bottom: $value !important;
  }
  .pt--#{$space} {
    padding-top: $value !important;
  }
}
@each $space, $value in $spacing {
  @include sm-tablet {
    .smt--m--#{$space} {
      margin: $value !important;
    }
    .smt--mx--#{$space} {
      margin-left: $value !important;
      margin-right: $value !important;
    }
    .smt--my--#{$space} {
      margin-top: $value !important;
      margin-bottom: $value !important;
    }
    .smt--ml--#{$space} {
      margin-left: $value !important;
    }
    .smt--mr--#{$space} {
      margin-right: $value !important;
    }
    .smt--mb--#{$space} {
      margin-bottom: $value !important;
    }
    .smt--mt--#{$space} {
      margin-top: $value !important;
    }
    .smt--p--#{$space} {
      padding: $value !important;
    }
    .smt--px--#{$space} {
      padding-left: $value !important;
      padding-right: $value !important;
    }
    .smt--py--#{$space} {
      padding-top: $value !important;
      padding-bottom: $value !important;
    }
    .smt--pl--#{$space} {
      padding-left: $value !important;
    }
    .smt--pr--#{$space} {
      padding-right: $value !important;
    }
    .smt--pb--#{$space} {
      padding-bottom: $value !important;
    }
    .smt--pt--#{$space} {
      padding-top: $value !important;
    }
  }
}
@each $space, $value in $spacing {
  @include phone {
    .sm--m--#{$space} {
      margin: $value !important;
    }
    .sm--mx--#{$space} {
      margin-left: $value !important;
      margin-right: $value !important;
    }
    .sm--my--#{$space} {
      margin-top: $value !important;
      margin-bottom: $value !important;
    }
    .sm--ml--#{$space} {
      margin-left: $value !important;
    }
    .sm--mr--#{$space} {
      margin-right: $value !important;
    }
    .sm--mb--#{$space} {
      margin-bottom: $value !important;
    }
    .sm--mt--#{$space} {
      margin-top: $value !important;
    }
    .sm--p--#{$space} {
      padding: $value !important;
    }
    .sm--px--#{$space} {
      padding-left: $value !important;
      padding-right: $value !important;
    }
    .sm--py--#{$space} {
      padding-top: $value !important;
      padding-bottom: $value !important;
    }
    .sm--pl--#{$space} {
      padding-left: $value !important;
    }
    .sm--pr--#{$space} {
      padding-right: $value !important;
    }
    .sm--pb--#{$space} {
      padding-bottom: $value !important;
    }
    .sm--pt--#{$space} {
      padding-top: $value !important;
    }
  }
}

// ----------
//  (F)ont (S)ize utilitie classes
// ----------
@each $size, $value in $font-size {
  .fs--#{$size} {
    font-size: $value !important;
  }
}
@include phone {
  @each $size, $value in $font-size {
    .fs--sm--#{$size} {
      font-size: $value !important;
    }
  }
}

// ----------
//  (F)ont (W)eight utilitie classes
// ----------
@each $weight, $value in $font-weight {
  .fw--#{$weight} {
    font-weight: $value !important;
  }
}
@include phone {
  @each $weight, $value in $font-weight {
    .fw--sm--#{$weight} {
      font-weight: $value !important;
    }
  }
}

// ----------
//  (L)ont (H)eight utilitie classes
// ----------
.lh--0 {
  line-height: 1em !important;
}

.lh--sm {
  line-height: 1.25em !important;
}

.lh--md {
  line-height: 1.5em !important;
}

.lh--lg {
  line-height: 1.7em !important;
}

// ----------
//  Quick flex positioning classes
// ----------
.d--f {
  display: flex;
}

.d--fi {
  display: inline-flex;
}

.f--dr {
  flex-direction: row !important;
}

.f--dc {
  flex-direction: column !important;
}

.f--jc {
  justify-content: center !important;
}

.f--je {
  justify-content: flex-end !important;
}

.f--js {
  justify-content: flex-start !important;
}

.f--jb {
  justify-content: space-between !important;
}

.f--ac {
  align-items: center !important;
}

.f--ae {
  align-items: flex-end !important;
}

.f--as {
  align-items: flex-start !important;
}

.f--ab {
  align-items: space-between !important;
}

.fw--no {
  flex-wrap: nowrap !important;
}

.fw--yes {
  flex-wrap: wrap !important;
}

.f-1 {
  flex: 1;
}

.fg--0 {
  flex-grow: 0;
}
@include phone {
  .sm--d--f {
    display: flex;
  }

  .sm--d--fi {
    display: inline-flex;
  }

  .sm--f--dr {
    flex-direction: row !important;
  }

  .sm--f--dc {
    flex-direction: column !important;
  }

  .sm--f--jc {
    justify-content: center !important;
  }

  .sm--f--je {
    justify-content: flex-end !important;
  }

  .sm--f--js {
    justify-content: flex-start !important;
  }

  .sm--f--jb {
    justify-content: space-between !important;
  }

  .sm--f--ac {
    align-items: center !important;
  }

  .sm--f--ae {
    align-items: flex-end !important;
  }

  .sm--f--as {
    align-items: flex-start !important;
  }

  .sm--f--ab {
    align-items: space-between !important;
  }

  .sm--fw--no {
    flex-wrap: nowrap !important;
  }

  .sm--fw--yes {
    flex-wrap: wrap !important;
  }
  .sm--fs--0 {
    flex-shrink: 0;
  }
  .sm--fg--0 {
    flex-grow: 0;
  }
}
// ----------
//  Text align and decoration utilitie classes
// ----------
.tac {
  text-align: center;
}

.tar {
  text-align: right;
}

.tal {
  text-align: left;
}

.taj {
  text-align: justify;
}

.tdn {
  text-decoration: none;
}

.tdu {
  text-decoration: underline;
}

.ttn {
  text-transform: none;
}

.ttu {
  text-transform: uppercase;
}
.ttc {
  text-transform: capitalize;
}
.vam {
  vertical-align: middle !important;
}
.vat {
  vertical-align: top !important;
}
.vab {
  vertical-align: bottom !important;
}
.text-trunk {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fsi {
  font-style: italic;
}

.fsn {
  font-style: normal;
}

@include phone {
  .sm-tal {
    text-align: left;
  }
  .sm-tac {
    text-align: center;
  }
}

// ----------
//  Text fonts utilitie classes
// ----------

.t--heading {
  font-family: $heading-font !important;
}
.t--body {
  font-family: $body-font !important;
}

// ----------
//  Dimensions utilitie classes
// ----------
.w--100 {
  width: 100% !important;
}

.w--max {
  width: max-content !important;
}
.w--max--100 {
  max-width: 100% !important;
}

.w--min {
  width: min-content !important;
}

.w--auto {
  width: auto !important;
}

.w--unset {
  width: unset !important;
}

.h--100 {
  height: 100% !important;
}

.h--max {
  height: max-content !important;
}

.h--min {
  height: min-content !important;
}

.h--auto {
  height: auto !important;
}

// ----------
//  Order utility classes
// ----------
@each $o in $order {
  .order-#{$o} {
    order: #{$o};
  }
  @include tablet {
    .order-tablet-#{$o} {
      order: #{$o};
    }
  }
  @include phone {
    .order-phone-#{$o} {
      order: #{$o};
    }
  }
}

// ----------
//  Box shadow utilitie classes
// ----------
.bs--0 {
  box-shadow: none !important;
}

.bs--sm {
  box-shadow: 0px 1px 12px 0px #00000025;
}

// ----------
//  icon container
// ----------
%icon-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@each $icon, $value in $icon-size-map {
  .ic--#{$icon} {
    @extend %icon-container;
    width: $value;
    height: $value;
  }
}

// ----------
//  icon container
// ----------

%avatar-default {
  width: 32px;
  height: 32px;
  min-width: 32px;
  border-radius: 50%;
  object-fit: cover;
  position: relative;
  z-index: 2;
}

.avatar-wrap,
.cs-avatar {
  @extend %avatar-default;
  &--sm {
    width: 32px;
    height: 32px;
    min-width: 32px;
  }
  &--md {
    width: 48px;
    height: 48px;
    min-width: 48px;
  }
  &--lg {
    width: 64px;
    height: 64px;
    min-width: 64px;
  }
  &--xl {
    width: 120px;
    height: 120px;
    min-width: 120px;
  }
}

.avatar-wrap--sm,
.cs-avatar--sm {
  @extend %avatar-default;
  width: 32px;
  min-width: 32px;
}

.avatar-wrap--md,
.cs-avatar--md {
  @extend %avatar-default;
  width: 48px;
  min-width: 48px;
}

.avatar-wrap--lg,
.cs-avatar--lg {
  width: 64px;
  min-width: 64px;
}

.avatar-wrap--xl,
.cs-avatar--xl {
  width: 120px;
  min-width: 120px;
}

.gradient-border {
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  top: -2px;
  left: -2px;
  position: absolute;
  z-index: 1;
  border: double 4px transparent;
  border-radius: 100%;
  background-image: linear-gradient(
      45deg,
      get-color('purple', '300'),
      #66d1dd 100%
    ),
    radial-gradient(circle at bottom left, get-color('purple', '300'), #66d1dd);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

// Display classes

.d--none {
  display: none;
}
.d--inline-block {
  display: inline-block;
}

@include tablet {
  .d--md--none {
    display: none;
  }
}

@include phone {
  .d--sm--none {
    display: none;
  }
  .d--sm--block {
    display: block;
  }
}

// Positioning Classes
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
@include phone {
  .sm-static {
    position: static;
  }
}

// Form Padding
.field-left {
  padding-right: 6px;
}
.field-right {
  padding-left: 6px;
}
.field-middle {
  padding-left: 6px;
  padding-right: 6px;
}

// Pill

%tag-default {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: inline-flex;
  padding: 12px;
  border-radius: 6px;
  align-items: center;
  height: max-content;
}

.cs-tag {
  &--neutral {
    @extend %tag-default;
    background-color: get-color('neutral', '50');
    color: get-color('neutral', '600');
    width: 100%;
  }
  &--info {
    @extend %tag-default;
    background-color: get-color('blue', '50');
    color: get-color('blue', '600');
    width: 100%;
  }
  &--warning {
    @extend %tag-default;
    background: get-color('warning', '50');
    color: get-color('warning', '600');
    width: 100%;
  }
  &--success {
    @extend %tag-default;
    background: get-color('success', '50');
    color: get-color('success', '700');
    width: 100%;
  }
  &--alert {
    @extend %tag-default;
    background-color: get-color('alert', '100');
    color: get-color('alert', '600');
    width: 100%;
  }
}

.cs-pill {
  &--primary {
    @extend %tag-default;
    background-color: get-color('purple', '100');
    color: get-color('purple', '600');
    border-radius: 500px;
    padding: 6px 12px;
  }
  &--neutral {
    @extend %tag-default;
    background-color: get-color('neutral', '100');
    color: get-color('neutral', '600');
    border-radius: 500px;
    padding: 6px 12px;
  }
  &--info {
    @extend %tag-default;
    background-color: get-color('blue', '50');
    color: get-color('blue', '600');
    border-radius: 500px;
    padding: 6px 12px;
  }
  &--warning {
    @extend %tag-default;
    background: get-color('warning', '50');
    color: get-color('warning', '600');
    border-radius: 500px;
    padding: 6px 12px;
  }
  &--success {
    @extend %tag-default;
    background: get-color('success', '50');
    color: get-color('success', '600');
    border-radius: 500px;
    padding: 6px 12px;
  }
  &--alert {
    @extend %tag-default;
    background-color: get-color('alert', '100');
    color: get-color('alert', '600');
    border-radius: 500px;
    padding: 6px 12px;
  }
}

.cs-table-pill {
  background-color: get-color('purple', '100');
  color: get-color('purple', '600');
  padding: 2px 10px;
  width: min-content;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 24px;
  min-width: max-content;
}

body .no-button-style {
  background: transparent !important;
  color: get-color('purple', '500') !important;
  svg * {
    stroke: get-color('purple', '500') !important;
  }
  &:disabled {
    svg * {
      fill: transparent !important;
    }
  }
  &:disabled:hover {
    svg * {
      stroke: get-color('purple', '500') !important;
      fill: transparent !important;
    }
  }
}
