@import 'scss/1-abstracts/';
.wrapper {
  display: flex;
  flex-direction: column;
  font-family: $body-font;
  label {
    @include form-label;
  }
  input {
    height: $form-element-height;
    border: 1px solid $border-color;
    color: $form-field-text-color;
    padding: 12px;
    border-radius: 6px;
    caret-color: get-color('purple', '500');
    &:focus {
      box-shadow: 0px 0px 0px 4px get-color('purple', '50');
      border: 1px solid get-color('purple', '300');
      outline: none;
    }
    &:focus-visible {
      outline: none;
    }
    &::placeholder {
      color: get-color('neutral', '300');
    }
    &.error {
      border: 1px solid red;
    }
    &:disabled {
      color: get-color('neutral', '300');
      background-color: get-color('neutral', '100');
    }
  }
}

.error {
  color: $error-color;
}
span.error {
  position: relative;
  top: 8px;
  font-size: get-font-size(1);
}
