@import 'scss/1-abstracts/';
.wrapper {
  & > p {
    @include form-label;
    margin-bottom: spacing(6);
  }
  &.grid .checkboxContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 40px;
    column-gap: 40px;
    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }
    @include phone {
      grid-template-columns: repeat(1, 1fr);
      gap: 16px;
    }
  }
  &.list .checkboxContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.error {
  color: $error-color;
  font-size: get-font-size(1);
}
