@import 'scss/1-abstracts/';

.wrapper {
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }

  label {
    color: get-color('neutral', '800');
    margin-left: 16px;
  }

  input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    // For iOS < 15 to remove gradient background
    background-color: transparent;
    // Not removed via appearance
    margin: 0;
    font: inherit;
    color: get-color('neutral', '300');
    width: $checkbox-width;
    height: $checkbox-width;
    min-width: $checkbox-width;
    border: 1.5px solid get-color('neutral', '300');
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:hover {
      cursor: pointer;
    }

    &:focus-visible {
      outline: 2px solid get-color('purple', '500');
    }
  }

  input[type='radio']::before {
    content: '';
    display: flex;
    width: calc($checkbox-width / 2);
    height: calc($checkbox-width / 2);
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: $primary-color;
  }

  input[type='radio']:checked {
    border: 1px solid $primary-color;
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }

  &.disabled {
    opacity: 0.5;
    &:hover,
    input:hover,
    label:hover {
      cursor: default;
    }
  }
}