@import 'scss/1-abstracts';

.wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: $bg-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  &.contain {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &.sm {
    background-color: transparent;
  }

  .border {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: get-color('purple', 500);
    background: $gradient-loader;
    animation: spin 0.8s linear 0s infinite;
  }
  &.lg .border {
    width: 150px;
    height: 150px;
    padding: 3px;
  }
  &.sm .border {
    width: 80px;
    height: 80px;
    padding: 3px;
  }
  &.sm .border {
    width: 20px;
    height: 20px;
    padding: 2px;
  }
}

.core {
  width: 100%;
  height: 100%;
  background-color: $bg-primary;
  border-radius: 50%;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
