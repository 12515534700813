@import 'scss/1-abstracts/';
.cs-antd-datepicker {
  .ant-picker {
    height: $form-element-height;
    border: 1px solid $border-color;
    color: $form-field-text-color;
    padding: 12px;
    border-radius: 6px;
    &:focus,
    &:focus-visible {
      border: 1px solid get-color('purple', '300');
      outline: none;
    }
    &.ant-picker-disabled {
      background-color: #ffffff;
      color: get-color('neutral', '400');
      border: 1px solid get-color('neutral', '300');
    }
    &.errorInput {
      border: 1px solid red;
    }
  }
}

// Leaving in case designers squack
// .ant-picker-dropdown tr .ant-picker-cell-in-view:last-of-type,
// .ant-picker-dropdown tr .ant-picker-cell-in-view:nth-last-of-type(2) {
//   color: $primary-color;
//   font-weight: 500;
// }

.ant-picker-dropdown .ant-picker-cell-selected {
  background: get-color('purple', '500') !important;
  color: $text-secondary !important;
}

.ant-picker-dropdown tr .ant-picker-cell:hover {
  background: get-color('purple', '100') !important;
}

.ant-picker-dropdown tr .ant-picker-cell:hover .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-selected .ant-picker-cell-inner {
  background: transparent !important;
}

.ant-picker-dropdown .ant-picker-footer {
  display: none;
}
.cs-antd-datepicker .ant-picker-focused {
  border: 1px solid get-color('purple', '300') !important;
  box-shadow: 0px 0px 0px 4px #e1e1fe;
}

.ant-picker-dropdown .ant-picker-cell-today .ant-picker-cell-inner::before {
  border: none !important; // THIS IS TODAY DATE ON DATEPICKER
}

.ant-picker-cell:not(.ant-picker-cell-in-view) {
  color: get-color('neutral', '400');
}

.ant-picker .ant-picker-input > input::placeholder {
  color: get-color('neutral', '400') !important;
}
