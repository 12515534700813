@mixin lg-tablet {
  @media (max-width: #{map-get($breakpoints, 'lg-tablet')}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{map-get($breakpoints, 'tablet') - 1px}) {
    @content;
  }
}

@mixin sm-tablet {
  @media (max-width: #{map-get($breakpoints, 'sm-tablet')}) {
    @content;
  }
}

@mixin phone {
  @media (max-width: #{map-get($breakpoints, 'phone') - 1px}) {
    @content;
  }
}

@mixin tabletMobileFirst {
  @media (min-width: #{map-get($breakpoints, 'tablet')}) {
    @content;
  }
}

@mixin lgTabletMobileFirst {
  @media (min-width: #{map-get($breakpoints, 'lg-tablet')}) {
    @content;
  }
}

@mixin phoneMobileFirst {
  @media (min-width: #{map-get($breakpoints, 'phone')}) {
    @content;
  }
}

@mixin smallDeviceMobileFirst {
  @media (min-width: #{map-get($breakpoints, 'small-device')}) {
    @content;
  }
}

@mixin small-device {
  @media (max-width: #{map-get($breakpoints, 'small-device')}) {
    @content;
  }
}

@mixin bp($value) {
  @media (max-width: $value) {
    @content;
  }
}

@mixin bpMobileFirst($value) {
  @media (min-width: $value) {
    @content;
  }
}

@mixin button($type: 'lg') {
  min-width: get-button-width($type);
  height: get-button-height($type);
  font-size: get-button-font($type);
}

@mixin max-width($val: $max-content-width) {
  max-width: $val;
}

@mixin transition($delay: 0s) {
  transition: ease-in-out $transition-speed $delay;
}

@mixin background-icon($size: get-icon-size()) {
  content: '';
  width: $size;
  height: $size;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin form-label($size: 'md') {
  background-color: transparent;
  margin-bottom: 4px;
  font-weight: 500;
  line-height: 1.5em;
  @if ($size == 'md') {
    font-size: 16px;
  } @else {
    font-size: 14px;
  }
}

@mixin wizard-wrapper {
  $w: calc(100% - 32px);
  max-width: calc(#{'min(#{$wizard-form-max-width}, #{$w} )'});
  width: 100%;
  margin: auto;
  @include tablet {
    max-width: $wizard-form-max-width;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@mixin page-wrapper($size: 'md') {
  $w: map-get($page-wrapper, $size);
  max-width: calc(#{'min(#{$w}, 90%)'});
  width: 100%;
  margin: auto;
}

//ICONS
@mixin icon-chat-bubble {
  background: linear-gradient(45deg, #9972f0 0%, #66d1dd 100%);
  box-shadow: 0px 20px 25px -5px rgba(16, 24, 40, 0.1),
    0px 8px 10px -6px rgba(16, 24, 40, 0.1);
  border-radius: 71.4286px;
}

//FILTERS
@mixin filter-white() {
  filter: brightness(0) invert(1);
}

@mixin filter-dark() {
  filter: invert(68%) sepia(0%) saturate(2081%) hue-rotate(20deg)
    brightness(95%) contrast(88%);
}

@mixin filter-primary() {
  filter: invert(14%) sepia(83%) saturate(6484%) hue-rotate(261deg)
    brightness(86%) contrast(110%);
}
