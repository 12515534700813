@import 'scss/1-abstracts';

.wrapper {
  position: relative;
  height: 100%;
  .previewContainer {
    height: 100%;
    overflow: scroll;
  }
}
