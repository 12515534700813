@import 'scss/1-abstracts';

.wrapper {
  $info-height: 48px;
  $footer-height: 96px;
  position: relative;
  height: 100%;
  .info {
    height: $info-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid get-color('neutral', '200');
    .name {
      font-size: 14px;
      color: get-color('neutral', '500');
      font-weight: 400;
    }
  }

  .controlls {
    display: flex;
    z-index: 10;
    button {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      svg {
        width: 100%;
        height: auto;
      }
    }
  }
  .previewContainer {
    height: calc(100% - $info-height - $footer-height);
    overflow: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .document {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    & > div {
      height: 100%;
    }
  }
}

.paginationHolder {
  height: 96px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ul {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      a {
        div {
          display: flex !important;
          align-items: center;
          justify-content: center;
          span:not([role='img']) {
            top: unset !important;
            bottom: unset !important;
            text-indent: 0px !important;
            word-wrap: normal;
            inset-inline-end: unset !important;
            inset-inline-start: unset !important;
          }
        }
      }
    }
  }
}
