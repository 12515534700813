@import 'scss/1-abstracts/';
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  & > div {
    display: flex;
  }
  input {
    appearance: none;
    margin-top: 0;
    width: $checkbox-width;
    height: $checkbox-width;
    min-width: $checkbox-width;
    border: 1px solid get-color('neutral', '300');
    border-radius: 4px;
    cursor: pointer;
    &:focus {
      border: 1px solid get-color('neutral', '300');
      box-shadow: none;
    }
    &:checked[type='checkbox'] {
      background-image: get-icon('checkmark', $text-secondary, 'fill-stroke');
      background-size: 14px auto;
      background-repeat: no-repeat;
      background-position: center;
      border-color: $primary-color;
      background-color: $primary-color;
    }
    &:focus-visible {
      outline: 2px solid get-color('purple', '500');
    }
  }
  label {
    @include form-label('md');
    margin-left: spacing(4);
    margin-bottom: 0;
    font-weight: 400;
  }
  &.block {
    flex-direction: column;
  }
  &.sm .label {
    font-size: 14px;
    line-height: 20px;
  }
}

.error {
  color: $error-color;
  font-size: get-font-size(1);
  display: block;
  position: relative;
  top: 0.5rem;
}
