@import 'scss/1-abstracts/';

.wrapper {
  width: 100%;
  height: $header-height;
  background-color: get-color('purple', '900');
  position: fixed;
  z-index: 3;
  display: flex;
  align-items: center;

  nav {
    position: relative;
    width: 100%;
    height: 100%;
    @include max-width($dashboard-content-width);
    margin: 0 auto;
    padding: 0 1rem 0 2rem;
    color: white;
  }
  &.hasSidebar nav {
    margin: 0 !important;
  }
  .navIRLabel {
    color: $text-secondary;
    margin-left: 10px;
    font-size: 14px;
  }
  .navItemLabel {
    line-height: 1;
    color: $text-secondary;
    margin-bottom: 2px;
    font-size: 14px;
  }
  .navItemTitle {
    display: block;
    color: get-color('neutral', '400');
    font-size: 14px;
  }
  .navLink {
    display: flex;
    align-items: center;
    padding: 20px;
  }
  .navDropdown {
    & > div {
      top: calc(100% + 1rem) !important;
    }
    :after {
      display: none;
    }
  }
  .navItemLabelSVG {
    margin-left: 8px;
  }
  .activeNav {
    background-color: get-color('neutral', '0');
    .navIRLabel {
      color: get-color('purple', '900');
    }
    svg * {
      fill: get-color('purple', '900');
    }
  }
}
