@import 'scss/1-abstracts/';
.wrapper {
  display: flex;
  flex-direction: column;
  font-family: $body-font;
  label {
    @include form-label;
  }
  input {
    font-size: 16px !important;
    font-family: $body-font !important;
    color: $form-field-text-color !important;
  }
  &.disabled {
    label {
      color: get-color('neutral', '400');
      font-size: 14px;
    }
    input {
      color: get-color('neutral', '400') !important;
    }
  }
}

.error {
  color: $error-color;
  font-size: get-font-size(1);
}
span.error {
  position: relative;
  top: 8px;
}
