// ----------
//  All font sizes will be used in rem and calculated by root value 16px;
// ----------
html,
body {
  font-size: 16px;
  color: $text-primary;
  font-family: $body-font;
}

p,
div,
span,
a {
  font-family: $body-font;
  font-size: 16px;
  line-height: 1.5em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font;
  line-height: 1.5em;
}

.cs-heading {
  font-family: $heading-font;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.02em;
}

.cs-label {
  @include form-label;
}

.va-middle {
  vertical-align: middle;
}

.capitalize {
  text-transform: capitalize;
}

@include phone {
  .cs-heading {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
  }
  .sm-ta-center {
    text-align: center;
  }
}
