@import 'scss/1-abstracts';
.wrapper {
  padding: 24px 20px;
  background: #f9f9f9;
  border: 1px solid #e9e9e9;
  border-radius: 6px;
  max-width: 285px;
  margin-left: auto;
  position: sticky;
  top: 76px;
  p {
    color: $text-primary;
    margin-bottom: 26px;
  }
  .title {
    font-weight: 600;
    margin-bottom: 16px;
  }
  .messageCustomer {
    cursor: pointer;
    display: flex;
    margin-bottom: 26px;
  }
  .messageCustomerLabel {
    color: #5514e6;
    margin-left: 10px;
  }
  .modalTitle {
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .verifiedTag {
    background: get-color('success', '100');
    color: get-color('success', '700');
    padding: 8px;
    border-radius: 6px;
    display: flex;
    font-weight: 600;
    align-items: center;
    svg {
      margin-right: 10px;
    }
  }
  .approvedLabel {
    font-weight: 600;
  }
  .verificationButton {
    min-width: initial;
    max-width: 100%;
    width: 100%;
    height: 44px;
    font-size: 1rem;
  }
}
