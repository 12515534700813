@import 'scss/1-abstracts';

.cs-search-input {
  position: relative;
  width: 300px !important;

  input {
    width: 100%;
    height: $form-element-height;
    border: 1px solid $border-color;
    color: $form-field-text-color;
    padding: 12px;
    border-radius: 6px;
    caret-color: get-color('purple', '500');
    &:focus {
      border: 1px solid get-color('purple', '300');
      box-shadow: 0px 0px 0px 4px #e1e1fe;
      outline: none;
    }
    &:focus-visible {
      outline: none;
    }
    &::placeholder {
      color: get-color('neutral', '300');
    }
    &.error {
      border: 1px solid red;
    }
    &:disabled {
      color: get-color('neutral', '300');
      background-color: get-color('neutral', '100');
    }
  }
  svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    fill: none;
  }
}

%tab-default {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 4px 12px;
  border-radius: 100px;
  align-items: center;
  height: max-content;
}

.cs-tab {
  &--active {
    @extend %tab-default;
    background-color: get-color('success', '100');
    color: get-color('success', '700');
  }
  &--expired {
    @extend %tab-default;
    background-color: get-color('alert', '100');
    color: get-color('alert', '700');
  }
  &--upcoming {
    @extend %tab-default;
    background-color: get-color('warning', '100');
    color: get-color('warning', '700');
  }
}

.cs-dropbox-image {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  img {
    height: 80%;
    width: auto;
  }
}

.cs-dropbox-landscape {
  aspect-ratio: 4/1 !important;
}

.ant-table-pagination {
  margin-top: 2rem !important;
}

.data-table {
  thead, tbody, tr {
      width: 100%;
  }
  tr:hover td {
    background-color: transparent !important;
  }
  thead th,
  thead td {
      border-bottom: 2px solid get-color('neutral', '300') !important;
  }
  tbody td {
      border-bottom: 1px solid get-color('neutral', '200') !important;
  }
  .ant-table-footer {
    background: transparent !important;
    padding-left: 0;
    padding-right: 0;
  }
}