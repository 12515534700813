@import 'scss/1-abstracts/';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: spacing(2);
  cursor: pointer;
  border: 1px solid;
  border-radius: $border-radius;
  // padding: spacing(3) spacing(5);
  color: $text-primary;
  width: max-content;
  height: $form-element-height;
  transition: ease-in-out 0.3s;
  svg path {
    transition: ease-in-out 0.3s;
  }
  &.icon {
    width: max-content;
    min-width: max-content;
  }
  &.sm {
    @include button('sm');
    svg {
      width: 10px;
      height: auto;
    }
  }
  &.md {
    @include button('md');
    svg {
      width: 16px;
      height: auto;
    }
  }
  &.lg {
    @include button('lg');
    svg {
      width: 18px;
      height: auto;
    }
    @include phone {
      min-width: initial;
    }
  }
  &.primary {
    background-color: $primary-color;
    color: $text-secondary;
    border-color: $primary-color;
    svg path {
      stroke: $text-secondary;
      fill: $text-secondary;
    }
    &:focus,
    &:hover {
      box-shadow: 0 0 5px $primary-color;
      background: get-color('purple', '600');
      border-color: $primary-color;
      outline: 0;
    }
    &:focus-visible {
      outline: 2px solid get-color('purple', '500');
    }
  }
  &.dark {
    background-color: get-color('neutral', 'dark');
    color: get-color('neutral', '100');
    svg path {
      stroke: $text-secondary;
      fill: $text-secondary;
    }
    &:focus,
    &:hover {
      box-shadow: 0 0 5px $primary-color;
      background: get-color('purple', '600');
      border-color: $primary-color;
      outline: 0;
    }
    &:focus-visible {
      outline: 2px solid get-color('purple', '500');
    }
  }
  &.secondary {
    background-color: transparent;
    color: $primary-color;
    border-color: $primary-color;
    svg path {
      stroke: $primary-color;
      fill: $primary-color;
    }
    &:focus,
    &:hover {
      color: $text-secondary;
      box-shadow: none;
      background: $primary-color;
      border-color: $primary-color;
      outline: 0;
      svg path {
        stroke: $text-secondary;
        fill: $text-secondary;
      }
      a {
        color: $text-secondary;
      }
    }
    &:focus-visible {
      outline: 2px solid get-color('purple', '500');
    }
    &:disabled {
      color: get-color('neutral', '300');
      background-color: transparent;
      border: 1px solid;
      border-color: get-color('neutral', '300');
      svg path {
        fill: get-color('neutral', '300') !important;
      }
    }
  }
  &.tertiary {
    background-color: transparent;
    color: $text-primary;
    border-color: $text-primary;
    svg path {
      stroke: $text-primary;
      fill: $text-primary;
    }
    &:focus,
    &:hover {
      background: $text-primary;
      border-color: $text-primary;
      color: $text-secondary;
      outline: 0;
      svg path {
        stroke: $text-secondary;
        fill: $text-secondary;
      }
    }
    &:focus-visible {
      outline: 2px solid get-color('purple', '500');
    }
  }
  &.quaternary {
    background-color: transparent;
    color: $text-primary;
    border-color: get-color('neutral', '300');
    svg path {
      stroke: $text-primary;
      fill: $text-primary;
    }
    &:focus,
    &:hover {
      background: $text-primary;
      border-color: $text-primary;
      color: $text-secondary;
      outline: 0;
      svg path {
        stroke: $text-secondary;
        fill: $text-secondary;
      }
    }
    &:disabled {
      color: $disabled-text;
      svg path {
        stroke: $disabled-text;
        fill: $disabled-text;
      }
    }
    &:focus-visible {
      outline: 2px solid get-color('purple', '500');
    }
  }
  &.simple {
    background-color: transparent;
    color: $primary-color;
    border-color: $primary-color;
    font-size: get-font-size(2);
    padding: 10px 16px;
    height: unset;
    &:focus,
    &:hover {
      color: $text-secondary;
      box-shadow: none;
      background: $primary-color;
      border-color: $primary-color;
      outline: 0;
      a {
        color: $text-secondary;
      }
    }
    &:focus-visible {
      outline: 2px solid get-color('purple', '500');
    }
  }
  &.danger {
    background-color: $error-color;
    border-color: $error-color;
    color: $text-secondary;
    svg path {
      stroke: $text-secondary;
    }
    &:hover {
      box-shadow: 0 0 5px $error-color;
      background: get-color('alert', '600');
    }
    &:focus-visible {
      outline: 2px solid get-color('alert', '600');
    }
  }
  &.edit {
    color: $primary-color;
    border: none;
    align-items: center;
    svg path {
      fill: $primary-color;
    }
  }
  &.text_danger {
    color: $error-color;
    border: none;
    align-items: center;

    svg path {
      stroke: $error-color;
    }
  }
  &.fill svg path {
    stroke: none !important;
  }
  &.stroke svg path {
    fill: none !important;
  }
  &:disabled {
    cursor: not-allowed;
    background: $disabled-bg;
    color: $disabled-text;
    border: none;
    svg path {
      fill: $disabled-text !important;
    }
    &:hover {
      box-shadow: none;
      background-color: $disabled-bg;
      color: $disabled-text;
      border: none;
      svg path {
        fill: $disabled-text !important;
      }
    }
  }
  @include phone {
    width: 100%;
    max-width: 100%;
  }
}
