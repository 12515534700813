@import 'scss/1-abstracts/';

.container .content {
  position: relative;
  left: $sidebar-width;
  top: $header-height;
  width: calc(100% - #{$sidebar-width});
  background-color: $bg-primary;
  @include transition;
  padding: 2.875rem 1rem 2.875rem 3.5rem;
  &.collapsed {
    left: $sidebar-width-collapsed;
    width: calc(100% - #{$sidebar-width-collapsed});
    @include transition;
  }
}

.container .contentWrap {
  width: 100%;
  @include max-width($dashboard-content-width);
}
