@import 'scss/1-abstracts/';

.wrapper {
  & > label {
    @include form-label;
    margin-bottom: 20px;
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .container.inline {
    flex-direction: row;
    gap: 32px;

    & > div {
      margin-bottom: 0;
    }
  }

  .container.block {
    & > div {
      border: 1px solid get-color('neutral', '300');
      border-radius: 10px;
      flex-flow: row-reverse;
      justify-content: space-between;
      gap: 10px;
      padding: 1rem;
      cursor: pointer;

      label {
        margin: 0;
      }

      &:has(input:checked) {
        border: 1px solid get-color('purple', 'base');
      }
    }
  }

  @include phone {
    div label {
      font-size: 14px;
    }

    .container.inline {
      gap: 28px;
    }
  }
}

.error {
  display: block;
  color: $error-color;
  font-size: get-font-size(1);
  margin-top: 8px;
}
