@import 'scss/1-abstracts/';
.wrapper {
  display: flex;
  flex-direction: column;
  font-family: $body-font;
  label {
    @include form-label;
  }
  .select > div[class$='control'] {
    height: $form-element-height;
    border: 1px solid $border-color;
    color: $form-field-text-color;
    border-radius: 6px;
    cursor: pointer;
    &:focus {
      border: 1px solid get-color('purple', '300');
      outline: none;
    }
    &:focus-visible {
      outline: none;
    }
    &::placeholder {
      color: get-color('neutral', '300');
    }
    &.error {
      border: 1px solid red;
    }
  }
}

.disabled {
  div[class$='singleValue'] {
    color: #cfcfcf;
  }
}

.select > div > div:nth-of-type(2) > span {
  display: none;
}

.select {
  [class$='MenuList'] {
    max-height: 172px;
    background-color: $bg-primary;
    border: 1px solid $border-color;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 4px;
    cursor: initial;
  }
  [class$='option'] {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 9px;
    cursor: pointer;
  }
}

.error {
  color: $error-color;
  font-size: get-font-size(1);
}
