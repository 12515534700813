@import 'scss/1-abstracts/';
.wrapper {
  & > p {
    @include form-label;
    margin-bottom: spacing(4);
  }

  .error {
    color: $error-color;
  }
}

.wrapper.displayRadio {
  .radioGroup {
    display: flex;
    align-items: center;
  }
  .radioGroup label {
    display: flex;
    align-items: center;
    @include form-label;
    font-weight: 400;
    &:hover {
      cursor: pointer;
    }
  }
  .radioGroup label:first-of-type {
    margin-right: 32px;
  }
  input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    // For iOS < 15 to remove gradient background
    background-color: transparent;
    // Not removed via appearance
    margin: 0;
    margin-right: 12px;
    font: inherit;
    color: get-color('neutral', '300');
    width: $checkbox-width;
    height: $checkbox-width;
    min-width: $checkbox-width;
    border: 1px solid get-color('neutral', '300');
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
  }
  input[type='radio']::before {
    content: '';
    display: flex;
    width: calc($checkbox-width / 2);
    height: calc($checkbox-width / 2);
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: $primary-color;
  }
  input[type='radio']:checked {
    border: 1px solid $primary-color;
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }
  @include phone {
    .radioGroup {
      margin-left: 0;
    }
  }
  &.disabled {
    opacity: 0.5;
    &:hover,
    input:hover,
    label:hover {
      cursor: default;
    }
  }
}

.wrapper.displayButton {
  .radioGroup {
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 8px;
  }
  .radioGroup > label {
    position: relative;
    width: 100%;
    padding: 12px 0;

    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    isolation: isolate;
  }
  .radioGroup > label:hover input[type='radio'] {
    cursor: pointer;
    background-color: get-color('purple', '50');
  }
  input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid get-color('neutral', '300');
    border-radius: $border-radius;
    z-index: -1;
  }
  input[type='radio']:checked {
    background-color: get-color('purple', '50');
    border: 1px solid $primary-color;
  }
}
