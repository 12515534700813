@import 'scss/1-abstracts';

.wrapper {
  position: relative;
  width: 100%;
  aspect-ratio: 3/2;
  input {
    width: 100%;
    height: 100%;
  }
}
