.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  overflow: hidden;
}

// Hack to hide GTM injected anchor
body > a {
  display: none;
}

#root {
  background-color: $bg-primary;
}

.row > * {
  padding-left: 0;
  padding-right: 0;
}

@include phone {
  .container,
  .container-fluid,
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.dropdown {
  > a {
    display: flex;
    align-items: center;
  }
  > a svg {
    margin-left: 6px;
  }
  .dropdown-menu {
    width: 240px;
    padding: 20px 0;
    top: 7px !important;
    right: -1px !important;
    left: initial !important;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  }
  .dropdown-item {
    display: flex;
    align-items: center;
    &:hover {
      background-color: get-color('neutral', '75');
    }
  }
  .dropdown-item svg {
    margin-right: 1.5rem;
  }
  .logout-menu-item {
    width: calc(100% - 32px);
    margin-left: 16px;
    margin-right: 16px;
    border-radius: 6px;
    border: 1px solid get-color('neutral', '300');
    text-align: center;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-weight: 500;
    transition: ease-in-out 0.3s;
    &:hover {
      color: white;
      background-color: get-color('purple', '500');
      border-color: get-color('purple', '500');
      svg path {
        stroke: white;
      }
    }
    svg {
      margin-right: 12px;
    }
  }
  svg path {
    transition: ease-in-out 0.3s;
  }
}

.cs-secure-badge {
  display: inline-flex;
  align-items: center;
  gap: 9px;
  margin: auto 0;
  background-color: get-color('neutral', '100');
  color: get-color('neutral', '600');
  padding: 8px 16px;
  border-radius: 18px;
  // LINE HEIGHT OF TEXT + 2*PADDING-Y / 2
  svg path {
    stroke: get-color('neutral', '600');
  }
  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}

.locked-secure-badge {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  margin: auto 0;
  background-color: get-color('neutral', '100');
  color: get-color('neutral', '600');
  padding: 8px 16px;
  border-radius: 18px;

  svg path {
    stroke: get-color('neutral', '0');
  }
  span {
    padding: 7px;
    border-radius: 100%;
    background: get-color('neutral', '300');
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}

// ANTD TABS STYLE

.cs-tabs .ant-tabs-nav {
  &::before {
    display: none;
  }
  .ant-tabs-tab {
    padding: 6px 16px;
    border-radius: 500px;
    margin: 0 0 0 4px;
    &:first-of-type {
      margin-left: 0;
    }
  }
  .ant-tabs-tab .ant-tabs-tab-btn {
    font-weight: 400 !important;
    color: get-color('purple', '400');
    font-size: 16px;
    line-height: 24px;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    background-color: get-color('purple', '50');
    .ant-tabs-tab-btn {
      color: get-color('purple', '500');
    }
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-nav-more {
    display: none;
  }
}

//ANTD Switch

.cs-switch.ant-switch {
  background-color: get-color('neutral', '300');
  &.ant-switch-checked {
    background-color: get-color('purple', '500');
  }
  &.ant-switch-checked:hover {
    background-color: get-color('purple', '500') !important;
  }
}

//ANTD Table

.cs-table.ant-table-wrapper {
  .ant-table-thead .ant-table-cell {
    font-weight: 600;
    border-bottom: 2px solid $border-color;
  }
  .ant-table-thead .ant-table-cell::before {
    display: none;
  }
}

//ANTD pagination

.ant-pagination .ant-pagination-item {
  background-color: get-color('neutral', '100');
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid get-color('purple', '100');
  &.ant-pagination-item-active {
    background-color: get-color('purple', '50');
    a {
      color: get-color('purple', '500');
    }
  }
  &:hover {
    border: 1px solid get-color('purple', '100');
    background-color: get-color('purple', '50');
    a {
      color: get-color('purple', '500');
    }
  }
}
.ant-pagination-item-link {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.ant-pagination-options {
  margin-left: 0 !important;
  &:before {
    content: 'Items per page';
    display: inline-block;
    margin-right: 10px;
    font-weight: 500;
  }
}
.ant-select {
  min-width: 75px;
  &.ant-select-focused .ant-select-selector,
  &.ant-select-open .ant-select-selector {
    border-color: transparent !important;
    box-shadow: 0px 0px 0px 4px get-color('purple', '50') !important;
    outline: 1px solid get-color('purple', '500') !important;
  }
  &:hover {
    border-color: get-color('neutral', '300') !important;
  }
  &:hover .ant-select-selector {
    border-color: get-color('neutral', '300') !important;
  }
}
.ant-select-arrow svg {
  margin-left: 5px;
  * {
    fill: get-color('neutral', 'base');
  }
}
.ant-select-dropdown {
  padding: 12px 0 !important;
  // box-shadow: 0px 0px 0px 4px get-color('purple', '50') !important;
}
.ant-select-selection-item {
  color: get-color('neutral', '800') !important;
}
.ant-select-item-option {
  border-radius: 0px !important;
  font-weight: 400 !important;
}
.ant-select-item-option:hover {
  background-color: get-color('purple', '50') !important;
}
.ant-select-item-option-selected {
  background-color: get-color('purple', '50') !important;
}

// Settings
.cs-tabs.settings {
  .ant-tabs-nav {
    margin-bottom: 2rem;
  }
  .ant-table-cell {
    background: transparent;
  }
  .ant-table-tbody .ant-table-cell {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .ant-tabs-nav-operations {
    display: none;
  }
  .ant-tabs-nav-wrap {
    &:before,
    &:after {
      display: none;
    }
  }
  @include phone {
    .ant-table-thead tr {
      display: none;
    }
    .ant-table-tbody tr {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid get-color('neutral', '200');
      padding-bottom: 1.5rem !important;
    }
    .ant-table-tbody td {
      display: block;
      width: 100%;
      border: 0;
      padding-bottom: 0 !important;
    }
    .ant-table-tbody td.ant-table-cell-row-hover {
      background-color: transparent !important;
    }
    .ant-table-tbody td:before {
      display: block;
      font-weight: 600;
      font-size: 0.875rem;
    }
    .ant-table-tbody td:nth-child(1):before {
      content: 'Application ID';
    }
    .ant-table-tbody td:nth-child(2):before {
      content: 'Company name';
    }
    .ant-table-tbody td:nth-child(3):before {
      content: 'Status';
    }
    .ant-table-tbody td:nth-child(4):before {
      content: '% completed';
    }
    .ant-table-tbody td:nth-child(5):before {
      content: 'Started';
    }
    .ant-table-tbody td button {
      width: auto;
    }
  }
}
.settings-label {
  label {
    font-size: 14px;
    color: get-color('neutral', '500');
  }
}
label.disabled {
  opacity: 0.5;
}

//Formik error

.cs-error {
  color: $error-color;
  font-size: get-font-size(1);
  margin-top: 8px;
}

.doing-great-title {
  color: get-color('turquoise', '500');
  line-height: 1;
  margin-bottom: 0px;
}

.finch-description {
  margin-bottom: 25px;
}

.codat-description {
  margin-bottom: 47px;
}

.avatar-image-wrapper {
  display: flex;
  justify-content: center;
}

.secure-connection-badge {
  background: get-color('neutral', '100');
  border-radius: 24px;
  display: inline-block;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 32px;
  margin-top: 8px;
}

.secure-connection {
  margin-left: 8px;
}

.review-row {
  border-bottom: 1px solid get-color('neutral', '200');
  padding: 18px 8px;
  margin-bottom: 0 !important;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.fd--row {
  display: flex;
  flex-direction: row;
}

.align-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-align-center {
  text-align: center;
}

.v-align-center {
  display: flex;
  align-items: center;
}

.review-item-label {
  margin-left: 8px;
}

.review-steps {
  margin: 32px 16px;
  svg {
    position: relative;
    top: -2px;
  }
  button {
    width: auto;
  }
}

@include tablet {
  .review-steps {
    padding-right: 16px;
  }
}

@include phone {
  .container-fluid > .row {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.pointer {
  cursor: pointer;
}

.quill {
  border: 1px solid get-color('neutral', '200');
  border-radius: 6px;
  min-height: 200px;
}

.ql-toolbar.ql-snow,
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border: none !important;
}

.ql-toolbar.ql-snow {
  border-bottom: 1px solid get-color('neutral', '200') !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.ant-tooltip-inner {
  width: 325px;
  padding: 16px !important;
  border-radius: 8px !important;
}

.ant-tooltip-inner p {
  font-size: 14px;
  font-weight: 20px;
}

.ant-table-thead .ant-table-cell {
  background-color: transparent !important;
}

.ant-table-thead .ant-table-cell::before {
  content: none !important;
}

.ant-pagination .ant-pagination-item {
  background: get-color('neutral', '100');
  color: get-color('neutral', '700');
  border: none;
}
.ant-pagination .ant-pagination-item.ant-pagination-item-active {
  background: get-color('purple', '50');
  color: get-color('purple', '500');
}

.ant-pagination .ant-pagination-options {
  position: absolute;
  left: 0;
}

.anticon {
  color: get-color('neutral', '200') !important;
}

.anticon.active {
  color: get-color('purple', '500') !important;
}

.ant-tooltip {
  display: none !important;
}

.ant-table-column-sort {
  background: transparent !important;
}

// Modals
.ant-modal-content {
  padding: 1.5rem !important;
}
.ant-modal-close {
  padding: 0 !important;
  background-color: transparent !important;
  top: 1.5rem !important;
  right: 1.5rem !important;
  width: 20px !important;
  height: 20px !important;
}
.ant-modal div[class$='-placeholder'] {
  color: get-color('neutral', '300') !important;
}
.ant-modal-mask {
  backdrop-filter: blur(8px);
}

.k-spreadsheet {
  width: 100% !important;
  height: 100% !important;
}

.cs-tooltip {
  $background: $text-secondary;
  width: 320px;
  opacity: 1;
  &.show {
    opacity: 1;
  }
  &.bs-tooltip-bottom {
    .tooltip-arrow::before {
      border-bottom-color: $background;
      box-shadow: 0px 1px 12px 0px #00000025;
    }
  }
  &.bs-tooltip-top {
    .tooltip-arrow::before {
      border-top-color: $background;
      box-shadow: 0px 1px 12px 0px #00000025;
    }
  }
  &.bs-tooltip-left {
    .tooltip-arrow::before {
      border-left-color: $background;
    }
  }
  &.bs-tooltip-right {
    .tooltip-arrow::before {
      border-right-color: $background;
    }
  }
  .tooltip-inner {
    max-width: 100%;
    border-radius: 8px;
    background-color: $background;
    color: get-color('neutral', '900');
    padding: 1rem;
    box-shadow: 0px 1px 12px 0px #00000025;
    text-align: left;
  }
  .tip-title {
    margin-bottom: 4px;
  }
  p:not(:last-of-type):not(:first-of-type) {
    margin-bottom: 1rem;
  }
}

.cs-tooltip-trigger {
  svg {
    height: 16px;
    width: auto;
  }
}

//SELECT
.cs-bulk-select > .ant-select-selector {
  height: 2.25rem !important;
  border: 1px solid $muted-color !important;
  color: $form-field-text-color;
  border-radius: 6px;
  cursor: pointer;
  input {
    height: 2.25rem !important;
  }
  .ant-select-selection-item,
  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
  &:focus {
    border: 1px solid get-color('purple', '300');
    outline: none;
  }
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: $muted-color;
  }
  &.error {
    border: 1px solid red;
  }
}

.cs-checkbox {
  appearance: none;
  margin-top: 0;
  width: $checkbox-width;
  height: $checkbox-width;
  min-width: $checkbox-width;
  border: 1px solid get-color('neutral', '300');
  border-radius: 4px;
  cursor: pointer;
  &:focus {
    border: 1px solid get-color('neutral', '300');
    box-shadow: none;
  }
  &:checked[type='checkbox'] {
    background-image: get-icon('checkmark', $text-secondary, 'fill-stroke');
    background-size: 14px auto;
    background-repeat: no-repeat;
    background-position: center;
    border-color: $primary-color;
    background-color: $primary-color;
  }
  &:focus-visible {
    outline: 2px solid get-color('purple', '500');
  }
}
.cs-select > .ant-select-selector {
  height: $form-element-height !important;
  border: 1px solid $muted-color !important;
  color: get-color('neutral', '500') !important;
  border-radius: 6px;
  box-shadow: 0px 1px 2px $form-element-shadow-color;
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  &:focus,
  &:active,
  &:focus-visible {
    border: 1px solid get-color('purple', '300') !important;
    outline: none !important;
  }

  &::placeholder {
    color: $muted-color;
  }
  &.error {
    border: 1px solid red;
  }
}
.cs-select .ant-select-arrow svg path {
  fill: get-color('neutral', '600') !important;
}
