@import 'scss/1-abstracts/';

%initials-default {
  border: 2px solid get-color('neutral', 'white');
  width: 56px;
  height: 56px;
  border-radius: 100%;
  background-color: get-color('purple', '50');
  display: inline-block;
}

.initialsAvatar {
  @extend %initials-default;

  &.noborder {
    border: 0;
  }

  &.mid {
    height: 40px;
    width: 40px;

    div {
      font-size: 1rem;
    }
  }

  &.small {
    height: 32px;
    width: 32px;

    div {
      font-size: 1rem;
    }
  }

  &.medium {
    height: 52px;
    width: 52px;
  }

  div {
    font-size: 1.25rem;
    font-weight: 500;
    color: get-color('purple', '500');
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
